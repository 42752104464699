import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import IconTypes, { getIconPath } from '../../../icons';
import TenantContext from '../../context/TenantContext';
import useCrossOriginAttribute from '../../useCrossOriginAttribute';
import type { IconSize } from '../../common/Icon/enums';
import { mediaDimensionsFromIconSize } from '../../../helpers/images/MediaHelper';

interface BadgeIconProps {
  alt: string;
  className: string;
  href: string;
  size: IconSize;
  testId?: string;
}

/**
 * The most basic building block for other Badge Icon-based components
 * @author Matias Aguero
 */
const BadgeIcon: React.FC<React.PropsWithChildren<BadgeIconProps>> = ({
  alt,
  className,
  href,
  size,
  testId = 'Badge.Icon'
}) => {
  const tenant = useContext(TenantContext);
  const crossOrigin = useCrossOriginAttribute();
  const cx = useClassNameMapper();
  const dimensions = mediaDimensionsFromIconSize(size);
  let badgeHref = href || getIconPath(IconTypes.BadgeUnknownIcon);

  // add url base path to default badge icon
  if (badgeHref.startsWith('/static')) {
    badgeHref = UrlHelper.prefixBasePath(tenant, badgeHref);
  }

  const badgeIconUrl = UrlHelper.adjustImageServerPageUrl(badgeHref, dimensions, true);

  return (
    <img
      src={badgeIconUrl}
      alt={alt}
      data-testid={testId}
      className={cx(className)}
      crossOrigin={crossOrigin(badgeHref)}
    />
  );
};

export default BadgeIcon;
