import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import UserAvatar from '@aurora/shared-client/components/users/UserAvatar/UserAvatar';
import UserBiography from '@aurora/shared-client/components/users/UserBiography/UserBiography';
import UserLogin from '@aurora/shared-client/components/users/UserLogin/UserLogin';
import UserRank from '@aurora/shared-client/components/users/UserRank/UserRank';
import type { UserPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { EndUserPages } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import type { ForwardRefExoticComponent, PropsWithoutRef, RefAttributes } from 'react';
import React, { forwardRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { UserKudosType } from '../../../types/enums';
import type {
  FeaturedBadgesQuery,
  FeaturedBadgesQueryVariables,
  UserViewFragment,
  UserViewQuery,
  UserViewQueryVariables
} from '../../../types/graphql-types';
import featuredBadgesQuery from '../../badges/FeaturedBadgesList/FeaturedBadges.query.graphql';
import FeaturedBadgesList from '../../badges/FeaturedBadgesList/FeaturedBadgesList';
import useBadgeProperties from '../../badges/useBadgeProperties';
import type { ExtendedOverlayInjectedProps } from '../../extendedOverlayTrigger/ExtendedOverlayTrigger/ExtendedOverlayTrigger';
import UserFollowersCount from '../UserFollowersCount/UserFollowersCount';
import UserKudos from '../UserKudos/UserKudos';
import UserMessagesCount from '../UserMessagesCount/UserMessagesCount';
import UserRegistrationDate from '../UserRegistrationDate/UserRegistrationDate';
import UserSolutionCount from '../UserSolutionCount/UserSolutionCount';
import userQuery from '../UserView.query.graphql';
import localStyles from './UserHoverCard.module.pcss';

const log = getLog(module);

interface Props extends ExtendedOverlayInjectedProps {
  /**
   * The user to create the card for.
   */
  entity: UserViewFragment;
}

/**
 * Displays Hover Card on hover of any user link.
 *
 * @author Neha Anandpara, Willi Hyde
 *
 */
const UserHoverCard: ForwardRefExoticComponent<
  PropsWithoutRef<Props> & RefAttributes<HTMLElement>
> = forwardRef<HTMLDivElement, Props>(function UserHoverCardForward(
  { entity: user, style, onMouseEnter, onMouseLeave },
  ref
) {
  const cx = useClassNameMapper(localStyles);
  const { Link } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();
  const { uid, id: userId, login } = user;
  const { badgesEnabled, badgePropertiesLoading } = useBadgeProperties(module);

  const {
    data: userQueryData,
    loading: userQueryLoading,
    error: userQueryError
  } = useQueryWithTracing<UserViewQuery, UserViewQueryVariables>(module, userQuery, {
    variables: {
      id: userId,
      useAvatar: true,
      useRank: true,
      useMessagesCount: true,
      useBiography: true,
      useRegistrationData: true,
      useFollowersCount: true,
      useKudosCount: true,
      useSolutionCount: true
    }
  });

  const featuredBadgesQueryResult = useQueryWithTracing<
    FeaturedBadgesQuery,
    FeaturedBadgesQueryVariables
  >(module, featuredBadgesQuery, {
    variables: {
      userId
    },
    skip: !badgesEnabled
  });

  if (!userQueryData || userQueryLoading || userQueryError || badgePropertiesLoading) {
    if (userQueryError) {
      log.error(
        `Error retrieving user data for hover card for user with id ${user.id}`,
        userQueryError
      );
    }

    return null;
  }

  const {
    kudosReceivedCount,
    messagesCount,
    followersCount,
    solutionsCount,
    biography,
    rank,
    registrationData
  } = userQueryData.user;
  const userData = userQueryData.user;
  const Component = 'a';
  const showMessagesCount = messagesCount > 0;
  const showFollowersCount = followersCount > 0;
  const showKudosReceivedCount = kudosReceivedCount > 0;
  const showSolutionsCount = solutionsCount > 0;
  const showMetadata =
    showMessagesCount || showFollowersCount || showKudosReceivedCount || showSolutionsCount;
  const metaDataCount = [messagesCount, followersCount, kudosReceivedCount, solutionsCount].filter(
    count => count > 0
  ).length;

  /**
   * Renders the avatar of the user.
   */
  function renderUserAvatar(): React.ReactElement {
    return (
      <Link<UserPageAndParams>
        route={EndUserPages.UserPage}
        params={{ userId: uid?.toString(), login: getCaseSensitivePath(login) }}
        passHref
      >
        <Component
          className={cx('lia-g-hovercard-link lia-g-hovercard-icon')}
          data-testid="user-link"
          title={login}
        >
          <UserAvatar user={userData} size={IconSize.PX_80} />
        </Component>
      </Link>
    );
  }

  /**
   * Renders the login of the user.
   */
  function renderUserLogin(): React.ReactElement {
    return (
      <Link<UserPageAndParams>
        route={EndUserPages.UserPage}
        params={{ userId: uid?.toString(), login: getCaseSensitivePath(login) }}
        passHref
      >
        <Component className={cx('lia-g-hovercard-title')} data-testid="user-link" title={login}>
          <UserLogin className={cx('mb-0')} user={userData} as="h4" />
        </Component>
      </Link>
    );
  }

  /**
   * Renders the biography of the user.
   */
  function renderUserBiography(): React.ReactElement {
    return (
      <UserBiography
        user={userData}
        as="span"
        clampLines={3}
        className={cx('lia-g-hovercard-info')}
      />
    );
  }

  /**
   * Renders the registration data of the user.
   */
  function renderRegistrationDate(): React.ReactElement {
    return (
      <UserRegistrationDate
        user={userData}
        textKey="withPrefix"
        className={cx('lia-g-hovercard-meta-text')}
      />
    );
  }

  /**
   * Renders the user rank.
   */
  function renderUserRank(): React.ReactElement {
    return (
      <UserRank
        user={userData}
        as="span"
        className={cx('lia-g-hovercard-meta-text lia-user-rank')}
      />
    );
  }

  /**
   * Renders the kudos count.
   */
  function renderUserKudos(type: UserKudosType): React.ReactElement {
    return (
      <UserKudos
        userKudosType={type}
        user={userData}
        useText={false}
        useTable
        countElement="h6"
        as="div"
        className={cx('lia-g-hovercard-data-item')}
        countClassName={cx('lia-g-hovercard-data-number')}
        textClassName={cx('lia-g-hovercard-data-title')}
      />
    );
  }

  /**
   * Renders the messages count of the user.
   */
  function renderUserMessagesCount(): React.ReactElement {
    return (
      <UserMessagesCount
        user={userData}
        useTable
        useText={false}
        as="div"
        countElement="h6"
        className={cx('lia-g-hovercard-data-item')}
        textClassName={cx('lia-g-hovercard-data-title')}
        countClassName={cx('lia-g-hovercard-data-number')}
      />
    );
  }

  /**
   * Renders the followers count of the user.
   */
  function renderFollowersCount(): React.ReactElement {
    return (
      <UserFollowersCount
        user={userData}
        as="div"
        countElement="h6"
        className={cx('lia-g-hovercard-data-item')}
        textClassName={cx('lia-g-hovercard-data-title')}
        countClassName={cx('lia-g-hovercard-data-number')}
      />
    );
  }

  /**
   * Renders user solution count.
   */
  function renderUserSolution(): React.ReactElement {
    return (
      <UserSolutionCount
        user={userData}
        useTable
        useText={false}
        as="div"
        countElement="h6"
        className={cx('lia-g-hovercard-data-item')}
        textClassName={cx('lia-g-hovercard-data-title')}
        countClassName={cx('lia-g-hovercard-data-number')}
      />
    );
  }

  /**
   * Renders user badges list.
   */
  function renderUserBadgesList(): React.ReactElement {
    return featuredBadgesQueryResult.data &&
      featuredBadgesQueryResult.data.featuredBadges.length > 0 ? (
      <FeaturedBadgesList
        badges={featuredBadgesQueryResult.data.featuredBadges}
        className={cx('lia-user-badges-list')}
        size={IconSize.PX_40}
      />
    ) : null;
  }

  function renderBody(): React.ReactElement {
    return (
      <>
        {renderUserAvatar()}
        {renderUserLogin()}
        {rank && renderUserRank()}
        {registrationData && renderRegistrationDate()}
        {biography && renderUserBiography()}
        {renderUserBadgesList()}
      </>
    );
  }

  function renderMetaData(): React.ReactElement {
    return (
      <div className={cx('lia-g-hovercard-data', { 'justify-content-between': metaDataCount > 2 })}>
        {showMessagesCount && renderUserMessagesCount()}
        {showKudosReceivedCount && renderUserKudos(UserKudosType.RECEIVED)}
        {showFollowersCount && renderFollowersCount()}
        {showSolutionsCount && renderUserSolution()}
      </div>
    );
  }

  return (
    <div
      id={`UserHoverCard-${uid}`}
      ref={ref}
      style={style}
      role={'tooltip'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cx('lia-g-hovercard')}
    >
      {renderBody()}
      {showMetadata && renderMetaData()}
    </div>
  );
});

export default UserHoverCard;
