import BadgeIcon from '@aurora/shared-client/components/badges/BadgeIcon/BadgeIcon';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import type { UnstyledListTypeAndProps } from '@aurora/shared-client/components/common/List';
import { ListVariant } from '@aurora/shared-client/components/common/List/enums';
import List from '@aurora/shared-client/components/common/List/List';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { FeaturedBadgeFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './FeaturedBadgesList.module.pcss';

interface Props {
  /**
   * The badges to display.
   */
  badges: FeaturedBadgeFragment[];
  /**
   * Class name(s) to apply to the List items
   */
  className?: string;
  /**
   * The size for each badge
   */
  size?: IconSize;
}

const FeaturedBadgesList: React.FC<React.PropsWithChildren<Props>> = ({
  badges,
  className,
  size = IconSize.PX_60
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.FEATURED_BADGES_LIST
  );
  const cx = useClassNameMapper(localStyles);

  if (textLoading) {
    return null;
  }

  const listVariant: UnstyledListTypeAndProps<FeaturedBadgeFragment> = {
    type: ListVariant.UNSTYLED,
    props: {
      useTransitions: false,
      listItemClassName() {
        return cx('lia-g-mb-0');
      }
    }
  };

  return (
    <List items={badges} variant={listVariant} className={className}>
      {item => (
        <BadgeIcon
          href={item.badge.icon?.url}
          alt={formatMessage('badgeTitle', { title: item.badge.title })}
          className={cx(`lia-g-icon-size-${size} lia-badge`, {
            'lia-badge-unearned': !item.earned
          })}
          testId={`${item.earned ? 'Earned' : 'Unearned'}Badge.Img`}
          size={size}
        />
      )}
    </List>
  );
};
export default FeaturedBadgesList;
